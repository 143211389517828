.header_responsive {
  display: flex;
}
/* *******header responsive ******** */

.menueSection {
    background: rgb(18,205,33);
    background: linear-gradient(90deg, rgba(18,205,33,1) 0%, rgba(19,133,32,1) 100%);
}

@media only screen and (max-width: 600px) {
  .header_responsive {
    display: block;
    flex-direction: column !important;
    align-items: flex-start;
    height: 65px;
    margin-top: 5px;
    padding-right: 10px;
  }

  .address_email {
    display: none;
    flex-direction: column !important;
    margin: 10px 0 0 20px !important;
  }
  .header_address {
    flex-direction: row !important;
  }
  .header_email {
    flex-direction: row !important;
    margin-left: 0px !important;
  }
  .header_green {
    position: relative !important;
    top: 120px;
  }
  .logo_on_green_bar {
    display: none;
  }
  .header_buttons {
    padding-right: 0px;
    justify-content: flex-start;
  }
  .logo_and_text_onheader {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.logo {
  width: 60px;
  height: 60px;
}

.logo1 {
  width: 60px;
  height: 60px;
  padding: 0px;
  margin-left: 10px;
}

.navbar-logo {
  margin-top: 10px;
}
