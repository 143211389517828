@media only screen and (max-width: 600px) {
    .home-text1{
        width: 95%;
        margin-top: 20px;
    }
    .information_text{
        font-size: large;
        width: 80%;
    }
    .home_main_container{
        margin-top: 10px;
    }
}