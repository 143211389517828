.whoWeare{
font-size: 18px;
box-sizing: border-box;
color: #413F3B;
text-align: justify;
}

.whyus{
    margin: 192px 0px;
}

@media only screen and (max-width: 600px) {
.whoWeare_container{
width: 95%;
}
}