/*<-----Header CSS Start----->*/
.navbar-logo {
    background: linear-gradient(to right, #7c3aed, #d946ef, #f97316);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-decoration: none !important;
    font-size: 25px;
    font-weight: bold;
  }
  

.navbar-logo2 {
    color: white !important;
    text-decoration: none !important;
    font-size: 25px;
    font-weight: bold;
    padding: 0px 20px;
    margin-top: 10px;
}

.headerStickyTrue{
    visibility: visible;
}

.headerStickyFalse{
visibility: hidden;
}

.location-color{
    color: #138148 !important;
}

.underline-removed li:hover{
    background-color: #2eb26d !important;
    color: white !important;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    animation: smoothScroll 500ms forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-142px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header_buttons button{
    color: white !important;
    font-size: 18px !important;
}
/*<-----Home CSS end----->*/

/*<-----Home CSS Start----->*/
.home-background{
    /*background-image: url("src/image/cloudImg.jpeg");*/
    background-repeat: no-repeat;
    background-size: cover;
    height: 101vh;
}

/*<-----Home CSS end----->*/

.underline-removed {
    text-decoration: none !important;
}

.position-removed{
    position: unset !important;
}

.logo1{
    width: 60px;
    height: 60px;
    padding: 0px;
}

.card-text{
text-align: justify;
}
.card-title{
    font-weight: bold;
}

.home-text{
    text-align: justify;
}