@media only screen and (max-width: 600px) {
.footer_detail{
    flex-direction: column !important;
}
.our_value_text{
    width: 100% !important;
    text-align: justify !important;
    padding: 0px 10px !important;
}
.our_value_text p{
    margin-left: 5px;
}
.footer_address{
    padding-left: 10px;
    margin-top: 20px ;
}
.copyright_text{
    margin-left: 10px;
}
}